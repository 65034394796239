@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');



.customDivShadows {
    box-shadow: 2px 4px 120px -29px rgba(222, 247, 28, 0.6);
    -webkit-box-shadow: 2px 4px 120px -29px rgba(222, 247, 28, 0.6);
    -moz-box-shadow: 2px 4px 120px -29px rgba(222, 247, 28, 0.6);
}

.top-heading {
    /* color: #37ed81; */
    color: #000;
    text-align: center;
    font-weight: 700;
    font-family: Poppins sans-serif;
    font-size: 44px;
    background-color: #fff;
    padding: 20px 30px;
    border-bottom-right-radius:30px;
    border-bottom-left-radius: 30px;
    width: 90%;
    margin-left: 5%;
}

.cta-btn {
    text-align: center;
    padding: 20px 25px;
    font-size: 28px;
    font-weight: 600;
    font-family: Poppins sans-serif;
    background: linear-gradient(104deg, rgba(0, 86, 59, 0.9570421918767507) 0%, rgba(3, 192, 60, 1) 59%, rgba(34, 139, 34, 0.9318321078431373) 88%);
    border-radius: 10px;
    color: #f2f2f2;
}

.custom-headings {
    font-size: 44px;
    font-weight: 700;
    text-align: center;
    padding: 0px 50px;
    margin: 40px 0px;
}

.student-images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
}

.student-image-card {
    min-width: 32%;
    max-width: 33%;
    border: 1px solid white;
    height: auto;
    margin-right: 5px;
}

.custom-gradient-card {
    background: linear-gradient(90deg, #3f0d74, #8733a9);
    text-align: center;
    font-size: 18px;
    width: 50%;
    align-items: center;
    border: 1px solid white;
    padding: 20px 30px;
    border-radius: 10px;
    margin-top: 15px;
    font-weight: 600;
}

.shiksha-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 50px;
    justify-content: center;
}

.shiksha-card {
    background-color: #222222;
    text-align: center;
    width: 32%;
    display: flex;
    flex-direction: column;
    min-width: 32%;
    max-width: 33%;
    padding: 15px 20px;
    margin-bottom: 25px;
    margin-right: 10px;
    border-radius: 10px;
    height: 180px;
    justify-content: center;
}

.shiksha-card h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
}


.shiksha-card p {
    font-size: 16px;
    font-weight: 500;
}

.faq-wrapper {
    margin-top: 40px;
    margin-bottom: 10px;
}


.neet-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 50px;
    justify-content: center;
}

.neet-card {
    background-color: #222222;
    text-align: center;
    width: 48%;
    display: flex;
    flex-direction: column;
    min-width: 48%;
    max-width: 50%;
    padding: 15px 20px;
    margin-bottom: 25px;
    margin-right: 10px;
    border-radius: 10px;
    height: 180px;
    justify-content: center;
}

.neet-card span {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
}

.neet-card p {
    font-size: 16px;
    font-weight: 500;
}

.image-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin-top: 50px;
}

.image-card {
    max-width: 370px;
    height: auto;
    min-width: 350px;
    margin-right: 10px;
    margin-bottom: 20px;
}

.testimonial-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
}

.testimonial-card {
    max-width: 370px;
    height: auto;
    min-width: 350px;
    margin-right: 10px;
    margin-bottom: 20px;
    background-color: #222222;
    padding: 20px;
    border-radius: 10px;
}



.learning-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 50px;
    justify-content: center;
}

.learning-card {
    background-color: #222222;
    text-align: center;
    width: 45%;
    display: flex;
    flex-direction: column;
    min-width: 45%;
    max-width: 50%;
    padding: 15px 20px;
    margin-bottom: 25px;
    margin-right: 30px;
    border-radius: 10px;
    height: 180px;
    justify-content: center;
}

.learning-card h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
}

.learning-card p {
    font-size: 16px;
    font-weight: 500;
}

.bonus-container {
    width: 48%;
    min-width: 48%;
    max-width: 48%;

}

.bonus-card {
    background-color: #222222;
    border-radius: 10px;
    padding: 20px;
    margin-right: 20px;
    height: 330px;
}

.bonus-card h4 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.speaker-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.speaker-wrapper-mobile {
    display: none;
}

.speaker-card-even {
    display: flex;
    flex-direction: row;
    background-color: #222222;
    padding: 20px;
    border-radius: 10px;
}

.speaker-card-even .left {
    max-width: 70%;
    min-width: 70%;
    padding: 20px;
}

.speaker-card-even .right {

    max-width: 30%;
    min-width: 30%;
}

.speaker-card-odd {
    display: flex;
    flex-direction: row;
    background-color: #222222;
    padding: 20px;
    border-radius: 10px;
}

.speaker-card-odd .left {
    max-width: 30%;
    min-width: 30%;
}

.speaker-card-odd .right {
    max-width: 70%;
    min-width: 70%;
    padding: 20px;
}

.speaker-img {
    border-radius: 10%;
    width: 300px;
    height: 300px;
    -webkit-box-shadow: 0px 0px 25px 4px rgba(63, 13, 116, 1);
    -moz-box-shadow: 0px 0px 25px 4px rgba(63, 13, 116, 1);
    box-shadow: 0px 0px 25px 4px rgba(63, 13, 116, 1);
    border: 1px solid white;
}

.speaker-name {
    font-size: 22px;
    font-weight: 600;
    margin-top: 10px;
    color: #58fc85;
    margin-bottom: 10px;
    text-align: start !important;
}

.speaker-designation {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    line-height: 1;
    margin: 8px 0px;
}

.speaker-details {
    font-size: 14px;
    padding-right: 40px;
    margin-top: 25px;
}

.faq-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    margin-bottom: 50px;
}

.faq-card {
    background-color: #222222;
    margin-bottom: 15px;
    padding: 20px 25px;
    border-radius: 10px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    /* background-color: #1c1c1e; */
    padding: 16px;
    box-sizing: border-box;
}

.card {
    background: linear-gradient(135deg, #452583, #6b1b75, #d66d38);
    border-radius: 16px;
    padding: 24px;
    min-width: 860px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    color: white;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
}

.card-content {
    position: relative;
    z-index: 2;
}

.card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.card-title::after {
    content: '✨';
    margin-left: 8px;
}

.card-price {
    font-size: 18px;
    margin-bottom: 16px;
}

.apply-btn {
    background: linear-gradient(45deg, #ffac47, #e96d6d);
    border: none;
    border-radius: 30px;
    color: white;
    padding: 12px 24px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background 0.3s;
    margin-bottom: 24px;
}

.apply-btn:hover {
    background: linear-gradient(45deg, #e96d6d, #ffac47);
}

.benefits-list {
    list-style-type: none;
    padding: 0;
}

.benefits-list li {
    margin-bottom: 12px;
    position: relative;
    padding-left: 24px;
}

.benefits-list li::before {
    content: '✔';
    color: #ffac47;
    position: absolute;
    left: 0;
    top: 0;
}

.iframe-tag {
    width: 560px;
    height: 315px;
}

.last-heading {
    font-size: 44px;
    line-height:1.1;
    margin: 10px 0px;
    font-weight: 600;
    text-align: center;
    border: 1px solid white;
    border-radius: 20px;
    padding: 25px 30px;
    min-height: 200px;
}

.green-text {
    color: #58fc85
}

.green-text-gradient{
    background-image: linear-gradient(104deg, rgba(0, 86, 59, 0.9570421918767507) 0%, rgba(3, 192, 60, 1) 59%, rgba(34, 139, 34, 0.9318321078431373) 88%);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    display: inline-block;
}

@media (max-width: 1100px) {
    .iframe-tag {
        width: 340px;
        height: 250px;
        margin-left: 30px;
    }

}

@media (max-width: 768px) {

    .top-heading {
        font-size: 22px;
        padding: 0px 10px;
        font-weight: 700;
        font-family: Poppins sans-serif;
        
        text-align: center;
        padding: 10px 10px;
        border-bottom-right-radius:30px;
        border-bottom-left-radius: 30px;
        width: 90%;
        margin-left: 5%;
        margin-bottom: 20px;
    }

    .iframe-tag {
        width: 340px;
        height: 250px;
        margin-left: 30px;
    }

    .custom-headings {
        font-size: 24px;
        padding: 0px 20px;
        margin-top: 0px;
    }

    .card {
        max-width: 100%;
        padding: 16px;
    }

    .card-title {
        font-size: 20px;
    }

    .apply-btn {
        padding: 10px 20px;
        font-size: 14px;
    }

    .card-price {
        font-size: 16px;
    }

    .cta-btn {
        text-align: center;
        padding: 20px 25px;
        font-size: 16px;
        font-weight: 600;
        border-radius: 10px;
        color: #f2f2f2;
    }

    .kxeAX {
        min-width: 250px !important;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .kZPsOd {
        max-width: 100%;
        height: 150px;
        display: block;
    }


    .custom-gradient-card {
        background: linear-gradient(90deg, #3f0d74, #8733a9);
        text-align: center;
        font-size: 16px;
        width: 90%;
        align-items: center;
        border: 1px solid white;
        padding: 20px 20px;
        border-radius: 10px;
        margin-top: 15px;
        font-weight: 600;
    }


    .shiksha-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10px;
        justify-content: center;
    }

    .shiksha-card {
        background-color: #222222;
        text-align: center;
        width: 90%;
        display: flex;
        flex-direction: column;
        min-width: 90%;
        max-width: 95%;
        padding: 15px 20px;
        margin-bottom: 25px;
        margin-left: 10px;
        border-radius: 10px;
        height: 180px;
        justify-content: center;
    }


    .neet-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10px;
        justify-content: center;
    }

    .neet-card {
        background-color: #222222;
        text-align: center;
        display: flex;
        flex-direction: column;
        width: 90%;
        min-width: 90%;
        max-width: 95%;
        padding: 15px 20px;
        margin-bottom: 25px;
        margin-left: 10px;
        border-radius: 10px;
        height: 180px;
        justify-content: center;
    }

    .testimonial-wrapper {
        margin-left: 50px;
        margin-top: 15px;
    }


    .learning-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 50px;
        justify-content: center;
    }

    .learning-card {
        width: 90%;
        min-width: 90%;
        max-width: 100%;
        margin-bottom: 25px;
        padding: 40px 10px !important;
        margin-left: 30px;
        border-radius: 10px;
        height: 220px;
        justify-content: center;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* height: 100vh; */
        /* background-color: #1c1c1e; */
        padding: 16px;
        box-sizing: border-box;
    }

    .card {
        background: linear-gradient(135deg, #452583, #6b1b75, #d66d38);
        border-radius: 16px;
        padding: 24px;
        min-width: 360px;
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
        color: white;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .card-content {
        position: relative;
        z-index: 2;
    }

    .card-title {
        /* align-items: center !important;  */
    }


    .speaker-wrapper {
        display: none;
    }

    .speaker-wrapper-mobile {
        display: flex;
        flex-direction: column;
        width: 92%;
        justify-content: center;
        margin-left: 15px;
    }

    .speaker-mobile-card-even {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: #222222;
        padding: 20px 5px;
        border-radius: 25px;
        width: 100%;
        margin-bottom: 20px;
    }

    .speaker-mobile-card-even .left {
        max-width: 100%;
        min-width: 95%;
        padding: 20px;
    }

    .speaker-mobile-card-even .right {

        max-width: 100%;
        min-width: 95%;
    }

    .speaker-mobile-card-odd {
        display: flex;
        flex-direction: row;
        background-color: #222222;
        padding: 20px;
        border-radius: 10px;
    }

    .speaker-mobile-card-odd .left {
        max-width: 100%;
        min-width: 95%;
    }

    .speaker-mobile-card-odd .right {
        max-width: 100%;
        min-width: 95%;
        padding: 20px;
    }

    .speaker-mobile-img {
        border-radius: 10%;
        width: 250px;
        height: 250px;
        margin-left: 13%;
        -webkit-box-shadow: 0px 0px 25px 4px rgba(63, 13, 116, 1);
        -moz-box-shadow: 0px 0px 25px 4px rgba(63, 13, 116, 1);
        box-shadow: 0px 0px 25px 4px rgba(63, 13, 116, 1);
        border: 1px solid white;
    }

    .speaker-mobile-name {
        font-size: 28px;
        font-weight: 700;
        margin-top: 10px;
        color: #58fc85;
        margin-bottom: 10px;
        text-align: start !important;
    }

    .speaker-mobile-designation {
        font-size: 20px;
        font-weight: 500;
        font-style: italic;
        line-height: 1.2;
        margin: 8px 0px;
    }

    .speaker-mobile-details {
        font-size: 16px;
        /* padding-right: 40px; */
        margin-top: 25px;
    }

    .last-heading {
        font-size: 20px;
        line-height:1.1;
        margin: 10px 0px;
        font-weight: 600;
        text-align: center;
        border: 1px solid white;
        border-radius: 20px;
        padding: 25px 10px;
        min-height: 200px;
    }

    .faq-wrapper {
        margin-top: 10px;
        width: 100%;
        margin-bottom: 10px;
    }

    .faq-card {
        background-color: #222222;
        margin-bottom: 15px;
        margin-left: 10px;
        width: 95% !important;
        padding: 20px 25px;
        border-radius: 10px;
    }
}